@import '../../style/mixins';
@import '../../style/themes/default';

@pickerPopupPrefixCls: am-picker-popup;
@pickerPrefixCls: am-picker-col;

.@{pickerPopupPrefixCls} {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: @fill-base;
  padding-bottom: env(safe-area-inset-bottom);

  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @picker-zindex;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    transform: translateZ(1px);
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: @fill-mask;
    height: 100%;
    z-index: @picker-zindex;
    transform: translateZ(1px);

    &-hidden {
      display: none;
    }
  }

  &-header {
    background-image: -webkit-linear-gradient(top, #e7e7e7, #e7e7e7, transparent, transparent);
    background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
    background-position: bottom;
    background-size: 100% 1PX;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
    .hairline('bottom');

    & & {
      &-right {
        text-align: right;
      }
    }
  }

  &-item {
    color: @brand-primary;
    font-size: @font-size-heading;
    padding: @v-spacing-md @h-spacing-lg;
    height: @option-height;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &-active {
      background-color: @fill-tap;
    }
  }

  &-title {
    flex: 1;
    text-align: center;
    color: @color-text-base;
  }

  & &-close {
    display: none;
  }
}

.@{pickerPrefixCls} {
  flex: 1;
}

@brand-primary: #F06E1E;